// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-post-cats-query-js": () => import("./../../../src/components/post_cats_query.js" /* webpackChunkName: "component---src-components-post-cats-query-js" */),
  "component---src-components-post-layout-js": () => import("./../../../src/components/post_layout.js" /* webpackChunkName: "component---src-components-post-layout-js" */),
  "component---src-components-post-tag-query-js": () => import("./../../../src/components/post_tag_query.js" /* webpackChunkName: "component---src-components-post-tag-query-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advance-js": () => import("./../../../src/pages/advance.js" /* webpackChunkName: "component---src-pages-advance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-list-js": () => import("./../../../src/pages/list.js" /* webpackChunkName: "component---src-pages-list-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

